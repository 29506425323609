.date-range-picker-toast.single-date-picker {
  width: 256px;
  top: unset;
  margin-right: unset;
  background: #FFFFFF;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.date-range-picker-toast.single-date-picker .calendar-pointer {
  right: unset;
}
