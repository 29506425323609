
/**
 * Styles for toast with a list of channels.
 */
.channel-labels-container {
  position: relative;
}

.channel-labels-container span {
  margin-left: 5px;
}

.channel-labels-container .channels-list-toast .label {
  background-color: #FFF !important;
}

.channels-list-toast {
  display: none;
}

.channel-labels-container:hover .channels-list-toast {
  display: flex;
  width: 289px;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  max-width: none;

  z-index: 1000;
  border-radius: 8px;
  border: none;
  background-color: #FFF !important;

  position: absolute;
  left: 37px;
  top: 37px;
}

.channels-list-toast .toast-body {
  display: flex;
  flex-direction: column;
  gap: 10px;

  background-color: #FFF !important;
}

.channels-list-toast .pointer {
  position: absolute;
  top: -20px;
  left: 31px;
  background: transparent !important;
}

/**
 * Styles for multiselect-react-dropdown to tweak appearance
 * that CSS Customization cannot change.
 */
.multiselect-container .icon_down_dir::before {
  content: none;
}
.multiselect-container .icon_down_dir {
  right: 22px;
  width: 14px;
  float: right;
  display:block;
}

#edit_alert_form .multiselect-container .groupHeading:first-child {
  display: block;
}

.disabled-but-first li + li {
  pointer-events: none;
  opacity: 0.2;
}

/**
 * Styles for alert dialogs.
 */
#edit_alert_form .single-checkbox .inline-label {
  color: var(--black-white-bw-500, #01072C);
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

#edit_alert_form .recurring {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  align-self: stretch;
}

#edit_alert_form .sub-label {
  margin-bottom: 8px;
}

#edit_alert_form .sub-label .form-label {
  color: #000;
  /* Body/Small */
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

#edit_alert_form .dropdown-badges {
  overflow: hidden;
}

/**
 * Styles for the alert settings.
 */
.action-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
 }

.hidden-number-channels {
  display: inline;
  padding: 0px 4px 0px 4px;
  margin-left: 10px;
  border-radius: 2px 0px 0px 0px;
  background-color: #E6F0FF !important;
}

.data-table .expanded-cell {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.data-table .expanded-row-highlighted .h2 {
  color: var(--Neutral-900, #111827);
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  margin-right: 10px;
}

.data-table.fixed {
  table-layout:fixed
}

// a gap between tables
.view-container .gap {
  height: 40px;
  margin-top: 35px;
  margin-bottom: 35px;
}

.test-alert-tooltip-container{
  position: relative;
}
.send-test-alert-tooltip {
  width: fit-content;
  display: none;
}

.test-alert-tooltip-container:hover .send-test-alert-tooltip {
  display: block;
}


/**
 * Styles for alert history.
 */
.alert-history .filter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.alert-history .action-row {
  margin-top: 36px;
  margin-bottom: 36px;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}

.alert-history .date-range-picker {
  height: 48px;
}

.alert-history .data-table tr.expanded-row-highlighted * {
  vertical-align: top;
}
