// Here you can add other styles

.behavior-actions-container input{
  width:170px;
}

.behavior-actions-container .select-container{
  width:250px;
}

.button-table-cell {
  width: 60px;
}

.behaviors-table .actions-cell {
  width: 90px;
}

* {
  --cui-btn-focus-shadow-rgb: 255,255,255 !important;
}
.text-truncate-row{
  max-width: 700px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.truncate-row-buttons{
  display: flex;
  gap: 15px;
  align-items: center;
}
.async-tasks-toast {
  margin-left: -349px;
  position: absolute;
  z-index: 1000000;
  top: 101px;
  width: 384px;
  background: #FFFFFF;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.drill-down .async-tasks-toast{
  margin-left: -257px;
}

.async-tasks-toast .tasks-pointer {
  position: relative;
  top: -28px;
  left: 335px;
}

.async-tasks-toast .toast-header{
  font-family: 'Rajdhani', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  height: 52px;
  border: none;
  color: #000000;
}

.tasks-body {
  padding-left: 16px;
  padding-right: 16px;
}

.task-row {
  padding-top: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  width: 352px;
  height: 116px;
}

.task-row:last-child {
  border-bottom: none;
}

.task-item{
  line-height: 48px;
  color: #000000;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  height: 100px;
  padding-top: 30px;
  padding-left: 29px;
  width: fit-content;
  float: left;
  display: flex;
  flex-direction: column;
  max-width: 263px;
}

.task-container {
  padding-right: 14px;
  height: 100px;
}

.task-container:hover{
  background: rgba(207, 207, 207, 0.25);
  border-radius: 10px;
}

.task-item .task-message{
  width: 214px;
  line-height: 14px;
}

.task-item .time-label{
  color: grey;
}

.task-status-icon {
  float: right;
  margin-top:25px;
}

.task-spinner {
  float: left;
  margin-right: 5px;
  margin-top: 7px;
  margin-left: 2px;
}

.task-completed {
  float: left;
  margin-top: 7px;
  margin-left: 2px;
  margin-right: 5px;
}

.task-error {
  float: left;
  margin-top: 7px;
  margin-left: 2px;
  margin-right: 5px;
}

.task-details-button{
  float: none;
  margin-left: 10px;
}

.task-details-popover{
  z-index: 1000001;
}

.popover {
  z-index: 2000000;
}

.cache-behavior-types-select{
  width: 170px;
}

.alert {
  margin-bottom: 0px;
}


body{
  font-family: Rajdhani, sans-serif;
  color: #FFFFFF;
}

//@media only screen and (max-width: 1600px) {
//  body:not(.login) {zoom: 0.75}
//  .modal-backdrop {
//    zoom: 1.5;
//  }
//}

h1 {
  font-weight: 700;
}

h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
}

h2{
  font-family: Rajdhani;
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
}

h3 {
  font-family: 'Rajdhani', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}

h6 {
  font-family: 'Rajdhani', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
}


input:-internal-autofill-selected {
  background: transparent;
}

body, html {
  height: 100vh;
  margin: 0;
}

#root {
  height: 100vh;
  margin: 0;
}

.view-container{
  width:1440px;
  height: 100%;
  margin: auto;
}

.drill-down .view-container{
  width: 1152px;
}

.s3 {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.s2{
  text-transform: none;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.s1 {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.m1 {
  color: #000;
  font-family: Rajdhani;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
}

.view-container .action-row {
  display: flow-root;
  position: relative;
  margin-top: 36px;
  margin-bottom: 20px;
}

.view-container .action-row.action-row-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.view-container .action-row .action-row-col {
  height: inherit;
  width: fit-content;
  padding-right: 29px;
  margin-right: 29px;
  border-right: 2px solid rgba(0, 0, 0, 0.3);
}

.wrapper.drill-down {
  padding-left: 308px !important;
}

.domains.view-container .action-row {
  height: fit-content;
}

.import-btn {
  float: right;
  margin-right: 10px;
}

.clickable {
  cursor: pointer;
}

.origins .data-table button:first-child{
  margin-left: 10px;
}

.view-container.domains .domain-cell{
  border-right: 3px solid #E5EDFF !important;
}

.destination-icon {
  margin-right: 10px;
}

.destination-wrapper{
  display: flex;
  max-width: 300px;
  align-items: center;

}
.view-container.domains .drag-icon{
  cursor: pointer;
}

.path-item {
  display: flex;
  height: fit-content;
  padding: 0 8px;
  align-items: center;
  gap: 8px;
  color: #313131;
  font-family: Rubik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.96px;
  text-transform: uppercase;
  border-radius: 4px;
  background: #EDF0FF;
  margin-bottom: 4px;
  width: fit-content;
  cursor: default;
}

.path-item .path-icon {
  display: none;
}

.path-item:hover .path-icon{
  display: block;
}

.path-item:hover {
  border-radius: 4px;
  background: #0029FF;
  color: #FFFFFF;
}

.domain-with-aliases .path-item{
  margin-top: 6px;
}

.view-container.domains .add-path.ibutton button {
  font-size: 12px;
  line-height: 22px;
}

.view-container.domains .add-path.ibutton img{
  margin-top: 4px;
  width: 12px;
  height: 12px;
}

.view-container.domains .add-path.ibutton .btn-secondary{
  padding-top: 0;
  padding-right: 8px;
  padding-left: 8px;
}

.view-container.domains .add-path button{
  height: 24px;
}

.view-container.domains .add-path.add-alias {
  margin-top:4px;
}


.view-container.domains .show-all-paths{
  cursor: pointer;
  color: var(--neutral-900, #111827);
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline;
  margin-top: 4px;
}

.verify-confirmation-text{
  margin-top: 20px;
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #000;
}

.edit-realtime-log .aws-creds-type{
  margin-bottom: 10px;
}

.edit-realtime-log .aws-credentials-fields{
  margin-left: 16px;
}

.origin-set-host{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 900px;
}

.simplebar-content-wrapper{
  overflow-x: hidden;
}

.cancel-save-buttons{
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  gap: 16px;
}

.Toastify__toast-container--top-right {
  z-index: 25000 !important;
}

.settings-disabled{
  opacity: 0.4;
  pointer-events: none;
}

.email-verified-container{
  padding-top: 300px;
}
