.commitment {
  .provider-icon {
    width: 23px;
    height: 23px;
    margin-right: 8px;
  }

  .commitment-config {
    display: flex;
    flex-wrap: nowrap;
  }

  .commitment-row {
    display: flex;
    justify-content: space-between;

    .filters {
      display: flex;
      justify-content: flex-end;
      gap: 8px;
    }

    .date-range-buttons {
      margin: 10px;
    }

    .date-range-picker-toast.single-date-picker {
      position: absolute;
      z-index: 1000;
      top: 58px;
      right: -6px;
      width: 256px;
    }
  }
}

#edit_commitment_form {
  .checkbox {
    width: 24px;
    height: 24px;
  }

  .provider-icon {
    width: 23px;
    height: 23px;
    margin-right: 8px;
  }
  
  .optionContainer .option {
    display: flex;
  }

  .multiSelectContainer input {
    background: transparent;
    border: none;
    margin-top: 3px;
    height: 32px;
  }

  .multiSelectContainer input.searchBox {
    width: 100%;
  }

  .action-form-line {
    flex-direction: column;
    align-items: stretch;
  }

}
