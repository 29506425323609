.isidebar{
  width: 308px;
  background: #01072C;
  position: fixed;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.isidebar .simplebar-placeholder {
  display: none;
}
.isidebar.settings{
  background: #FFFFFF;
  border-right: 1px solid rgba(0, 0, 0, 0.3);;
}

.isidebar .side-bar-logo{
  width: 158px;
  height: 34px;
  margin-left: 24px;
  margin-top: 37px;
  margin-bottom: 10px;
  cursor: pointer;
}

.isidebar.settings .side-bar-logo {
  height: 64px;
}

.isidebar .service-name{
  color: #FFF;

  /* Special/Button */
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1.12px;
  text-transform: uppercase;
  text-overflow: ellipsis;
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
}

.isidebar .nav-item {
  float: left;
  width: 275px;
}

.isidebar .nav-link {
  height: 48px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: white;
}

.isidebar .nav-item{
  height: 48px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: white;
  line-height: 48px;
}

.isidebar.settings .nav-item{
  color: #000000;
  width: 274px;
}

.isidebar.settings .nav-link{
  color: #000000;
}

.isidebar .nav-link.active {
  background: #0029FF;
  width: 308px;
  color: #FFFFFF;
}

.isidebar .nav-link.active:hover {
  background: #0029FF;
}

.isidebar .nav-link:hover {
  background: transparent;
  color: #FFFFFF;
}

.isidebar.settings .nav-link:hover {
  color: #000000;
}

.isidebar .nav-item-container .hover{
  display: none;
}

.isidebar.settings .nav-submenu{
  background: #FFFFFF;
}

.isidebar .nav-item-container:hover .hover{
  display: block;
  background: #0029FF;
  width: 13px;
  content:"";
  height: 48px;
  position: absolute;
  top: 0;
  right: 0;
}

.isidebar .nav-submenu > .nav-item-container.sublevel {
  padding-left: 64px;
}

.isidebar .nav-submenu > .nav-submenu > .nav-item-container.sublevel {
  padding-left: 26px;
}

.nav-item-container {
  height: 48px;
  position: relative;
  cursor: pointer;
}
.isidebar .nav-icon{
  max-width: 20px;
  margin-left: 26px !important;
  margin-right: 10px;
}

.isidebar .nav-submenu{
  background: #292C3F;
}

.isidebar .nav-submenu a{
  padding-left: 64px;

}

.service-name-container{
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid #FFF;
  background: rgba(255, 255, 255, 0.10);
  width: 238px;
  margin-top: 26px;
  margin-bottom: 34px;
  margin-left: 25px;
}

.service-name-container img{
  cursor: pointer;
}

.nav-item-container img.drop{
  position: absolute;
  right: 16px;
  top: 20px;
}

.app-setting-sidebar-nav > .nav-item-container {
  margin-left: 24px;
  border-bottom: 1px solid #0000004D
}

.app-setting-sidebar-nav .nav-submenu a {
  padding-left: 0px;
}

.side-bar-service-cname{
  margin-top: 10px;
  width: 243px;
  height: 24px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  background: #E5F3FF;

  color: #445164;
  justify-content: center;
  margin-left: 25px;
  cursor: pointer;
}

.side-bar-service-cname img{
  width: 11px;
}

.cname-dialog-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.cname-dialog-title{
  font-family: 'Rajdhani', sans-serif;
  justify-self: center;
  color: #000000;
  font-weight: 700;
  line-height: 20px;
  font-size: 20px;
  align-self: center;
}

.cname-dialog-desc{
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: black;
  text-align: center;
}

.copy-cname{
  position: absolute;
  top: 166px;
  right: 10px;
}
