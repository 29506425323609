.login-background {
  width: 100%;
  height: 100vh;

  background: url("/assets/login/login_back.svg") no-repeat center center;
  background-size: cover;
}

.login-form-background {
  height: 100vh;
  background: white center center;
  background-size: cover;
  border-radius: 0 0 200px 0;

  min-width: 720px;
  width:50vw;
  color:#000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form-container {
  margin: auto;
  width: 400px;
}
.login-form-container .logo {
  width: 158px;
  margin: auto auto 64px;
}

.login-form-container input{
  color: #000000 !important;
}

.login-form-container h1, .login-form-container h5 {
  text-align: center;
}

.login-form-container h5 {
  margin-bottom: 64px;
}

.login-form-container .ibutton {
  margin-bottom: 16px;
}

.login-form-container .ibutton button{
  width: 400px;
}

//
.login-form-container input:-webkit-autofill,
.login-form-container input:-webkit-autofill:hover,
.login-form-container input:-webkit-autofill:focus,
.login-form-container input:-webkit-autofill:active,
.login-form-container input:-internal-autofill-selected  {
  transition: background-color 5000s;
  -webkit-text-fill-color: #000 !important;
}

.login-form-container .register-link {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}

.login-form-container .register-link a{
  font-weight: 700;
  color: #000;
  margin-left: 3px;
}

.auth-error {
  padding: 8px 18px;

  width: 400px;
  height: 64px;

  background: #FFD600;
  border-radius: 5px;
}

.login-background .form-error-message {
  overflow: hidden;
  background-color: transparent;
  color: #CA1212;
  font-family: 'Rubik', sans-serif;
  background-image: none;
  font-weight: 400;
  padding: 0;
  gap: 4px;
 text-align: center;
}

