.account-item {
  width: 660.5px;
  min-width: 660.5px;
  height: 128px;

  background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(90deg, #01072C 0%, #182574 100%);
  box-shadow: 10px 19px 34px rgba(177, 193, 216, 0.8);
  border-radius: 10px;
  color: black;
  position: relative;
  margin-bottom: 64px;
  padding-top: 52px;
  padding-left: 48px;
  display: flex;
  flex-basis: 660px;
}

.account-item .border-overlay{
  border: none;
  border-radius: 10px;
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  cursor: pointer;
}

.account-item .border-overlay:hover {
  border: 2px #0066C0 solid;
  box-shadow: 35px 36px 34px rgba(177, 193, 216, 0.8);
}

.account-items {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}

.account-item .account-name {
  overflow: hidden;
  font-family: "Rajdhani", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 24px;
  color: #000000;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 505px;
}

.account-item .services-count{
  margin-left: 16px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);
}

.account-chooser-actions {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.view-container.services .account-chooser-actions {
  margin-top: 16px;
}
