.monitoring-geos-select {
  width: 520px;
  float: left;
  margin-right: 33px;
}

.geo-badge {
  background: #EDF0FF;
  border-radius: 4px;
  float: left;
  padding: 0 8px;
  cursor: pointer;
}

.geo-badge img {
  margin-right: 8px;
}

.monitoring-filter-providers{
  float: left;
  width: 251px !important;
}

.monitoring-filter-providers .multiselect-container .searchBox {
  width: 250px;
}

.monitoring-filter-providers .multiselect-container .highlightOption.highlight  {
  background: none;
}

.multiselect-container .option:hover  {
  background:#E6F0FF;
  color:black;
}

.availability-monitoring .stats-container{
  height: 669px;
  border: 3px solid #E5EDFF;
  border-radius: 5px;
  margin-top: 32px;
}

.performance-monitoring .stats-container{
  height: 577px;
  border: 3px solid #E5EDFF;
  border-radius: 5px;
  margin-top: 32px;
}

.security-monitoring .stats-container{
  height: auto;
}

.availability-over-time-graph, .security-over-time-graph, .performance-over-time-graph{
  height: 444px;
  background: #FFFFFF;
  padding: 10px;
}

.additional-availability-stats {
  height: 213px;
  border-top: 3px solid #E5EDFF;
  padding: 32px;
  display: flex;
}

.additional-performance-stats {
  height: 129px;
  border-top: 3px solid #E5EDFF;
  padding-left: 16px;
}

.provider-totals{
  width: 395px;
}

.provider-totals .progress-indicator{
  text-align: right;
  padding-right: 5px;
}

.region-failure-share{
  margin-left: auto;
}

.failure-share-pie {
  float: left;
  margin-right: 12px;
}

.choose-geo{
  margin: auto;
  width: fit-content;
}

.security-monitoring-rules-select {
  float: left;
}

.security-monitoring .graph-period-control{
  float: right;
}

.security-monitoring .paginated-table-container{
  margin-top: 10px;
}

.summary {
  width: 1152px;
  height: 110px;
  border: 2px solid #B0B2BE;
  border-radius: 5px;
  padding-left: 40px;
}

.summary-item {
  padding-left: 16px;
  padding-right: 16px;
  width: 260px;
  height: 110px;
  float: left;
}

.summary-item-title {
  float: left;
  border-left: 3px solid #0267FF;
  width: 80px;
  height: 48px;
  padding-left: 16px;
  margin-top: 29px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.5;
}

.summary-item-value{
  float: left;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  margin-top: 26px;
  margin-left: 16px;
}

.sampled-logs-title{
  font-family: 'Rajdhani', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  margin-top: 43px;
}

.sampled-logs-hint{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 16px;
}

.search-logs-field{
  margin-bottom: 48px;
}

.security-log-expanded th{
  height: 36px;
}

.security-log-expanded .top{
  height:40px;
  margin-bottom: 35px;
}

.security-log-expanded .tab-control{
  margin-bottom: 35px;
  height: 36px;
}

.security-log-expanded .tab-control div{
  float: left;
  border: 2px solid #B0B2BE;
  border-radius: 5px 0 0 5px;
  cursor: pointer;
  width: 100px;
  height: 36px;
  text-align: center;
  padding-top: 6px;
}

.security-log-expanded .tab-control div.selected{
  background: #E5EDFF;
}

.security-log-expanded .tab-control div:last-child{
  border-radius: 0 5px 5px 0;
}

.security-log-expanded .top .form-label {
  margin-right: 10px;
}

.security-log-expanded .top .form-label {
  margin-left: 16px;
}

.rule-set-title{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
}

.rule-icon {
  margin-right: 12px;
}

.data-table td.rule-action-blocked, .data-table td.rule-action-block{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #D80000;
}

.security-log-expanded .expand-icon{
  margin-left: 12px;
  cursor: pointer;
}

.headers-table, .rules-table {
  max-height: 300px;
  overflow: auto;
}

.headers-table td.header-value {
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.performance-monitoring .additional-filters, .availability-monitoring .additional-filters{
  margin-top: 58px;
  height: 258px;
}

.additional-filters .geo-filters{
  height: 100%;
  border-right: 3px solid #E5EDFF;
  width: 575px;
  float: left;
}

.performance-monitoring.view-container .action-row{
  height: 84px;
}

.additional-filters .filter-by-vendors{
  float: left;
  padding-left: 27px;
  width: 534px;
}

.availability-monitoring .graph-loading {
  position: relative;
  top: 136px;
  text-align: center;
}

.performance-monitoring .graph-loading {
  position: relative;
  top: 136px;
  text-align: center;
}

.waf-sampled-logs-table{
  margin-bottom: 48px;
}

.view-container.security-monitoring .form-error-message{
  margin-top: 24px;
}

.check-filter{
  float: left;
}

.measure-by {
  float: left;
  margin-left: 24px;
}

.no-data-label{
  font-family: 'Rajdhani', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 40px;
}


.logs .paginated-table-container{
  position: relative;
}

.logs .paginated-table-container .table-loading{
  position: absolute;
  width: 100%;
  top: 90px;
}

.security-monitoring.view-container .action-row{
  margin-bottom: 4px;
}

.security-monitoring .url-table-column {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.security-monitoring .security-log-expanded .url-table-column {
  max-width: 900px;
  overflow-wrap: anywhere;
}


.graph-footer-table th{
  border: none;
}

.metric-chooser{
  float: left;
  margin-left: 10px;
}
