.modal h5 {
  color: #000000;
}

.modal-header {
  border: none;
}

.modal-divider{
  border-top: 1px solid rgb(0, 0, 0,0.3);
  width: 100%;
  margin:18px 0px;
}

.modal-footer {
  border: none;
  padding-left: 0px;
  padding-right: 0px;
}

.modal-footer-center {
  border: none;
  justify-content: center;
  align-items: center;
}

.modal-body .s1{
  text-align: center;
}

.confirm h6{
  text-align: center;
  width: 100%;
}

.confirm .modal-footer{
  justify-content: center;
}

.modal-header img {
  margin-right: 9px;
}

.modal{
  z-index: 50000;
}

.modal-backdrop {
  z-index: 40000;
}

.modal.confirm .modal-header{
  padding-top: 36px;
}

.modal.confirm .modal-header .btn-close{
  display: none;
}
