//.idropdown {
//  border: 2px solid #B0B2BE;
//  border-radius: 5px;
//}

//.idropdown img{
//  height: 18px;
//}


.idropdown .dropdown-toggle::after{
  display: none;
}

.idropdown.error button{
  border-color:#D12F2F;
}

.idropdown button:disabled{
  border: 2px solid var(--black-white-bw-300, #555972);
  opacity: 0.2;
}

.idropdown .dropdown-toggle {
  display: block;
  background: #FFFFFF;
  color: #000000;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  height:48px;
  border: 2px solid #B0B2BE;
  border-radius: 5px;
}

.idropdown.sm .dropdown-toggle{
  height: 48px;
}

.idropdown .dropdown-toggle:hover{
  background: #E6F0FF;
  border: 2px solid #B0B2BE;
}

.idropdown.sm {
  height: 48px;
}

.idropdown .dropdown-toggle .down-arrow{
  float: right;
}

.idropdown .dropdown-toggle .selected-label{
  float: left;
  color: #01072C;
}

.idropdown .text{
  line-height: 24px;
  float: left;
}

.idropdown .drop-down-label {
  padding-top: 5px;
}

.idropdown .dropdown-item .drop-down-label {
  padding-top: 8px;
}

.idropdown img{
  margin-right: 10px;
  float: left;
}

.idropdown .down-arrow img{
  float: none;
}

//.idropdown .dropdown-toggle .toggle-content {
//  padding-top: 5px;
//}

.dropdown-menu {
  width: 100%;
  margin: 0;
  max-height: 300px;
  overflow: auto;
}

.idropdown.show .dropdown-menu{
  border-radius: 0 0 6px 6px;
  top:-1px;
  border-width: 2px;
  inset: -4px auto auto 0px !important;
  padding-top: 0px;
  padding-bottom: 4px;
}

.idropdown.show .dropdown-toggle {
  border-radius: 5px 5px 0 0;
}


.dropdown-item {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 48px;
  height: 48px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  background: #FFFFFF;
  color: #2F2F2F;
}

.dropdown-item .second-level {
  padding: 0px 16px 0px 16px;
}

.dropdown-item:hover {
  background: #E6F0FF;
}

.dropdown-item:active{
  background: #6BA6FF;
  color: #2F2F2F;
}

.dropdown-item:last-child{
  border-bottom: none;
}

.nav-dropdown .dropdown-item {
  border-bottom: none;
}

.dropdown-item img{
  margin-right: 10px;
}


.idropdown .dropdown-header {
  height: 56px;
  border-bottom: 1px solid #B0B2BE;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #01072C;
  line-height: 36px;
}

.dropdown-toggle.free-text-dropdown{
  padding: 0;
}

.drop-down-text-input {
  width: 100%;
  height: 100%;
  padding-left:24px;
}

.drop-down-text-input input{
  width: 100%;
  height: 100%;
  border: none;
}

.idropdown .delete-button {
  position: absolute;
  top: 8px;
  right: 8px;
}

.idropdown .item-icon {
  margin-top: 10px;
}
