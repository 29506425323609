.view-container .breadcrumb{
  margin-top: 39px;
}

#root .breadcrumb-item a{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  text-decoration: none;
}

#root .breadcrumb-item.special-breadcrumb a{
  font-weight: 700;
  color: #0029FF;
}

#root .breadcrumb-item{
  overflow: hidden;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  text-decoration: none;
  white-space: nowrap;
  max-width: 251px;
  text-overflow: ellipsis;
}

#root .breadcrumb-item::after{
  content: ">";
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: black;
  margin-left: 8px;
}

#root .breadcrumb-item:last-child::after{
  content: "";
}

#root .breadcrumb-item + .breadcrumb-item::before{
  content: "" !important;
  margin: 0 !important;
  padding: 0 !important;
}

